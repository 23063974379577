// IMPORT ADDONS
@import "addons/bootstrap/bootstrap";

/* purgecss start ignore */

// @import 'addons/font-awesome/font-awesome';
@import 'addons/owl-carousel2/owl.carousel';
@import "addons/layerslider/layerslider";
@import "addons/magnific-popup/magnific-popup";
// @import 'addons/cube-portfolio/cubeportfolio';
// @import 'addons/parallax-scroll/normalize';
// @import 'addons/parallax-scroll/main';
@import "_alertify";

/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import "reset";
@import "variables";
@import "functions";

/* GENERAL TEMPLATE */

body {
  background-color: $bg-color-body;

  h1.referencement {
    display: none !important;
  }
  .appear {
    opacity: 0;
    @include transition(opacity 0.75s ease-in);
  }
  .parallax,
  .parallax-slow,
  .parallax-fast {
    background-attachment: fixed;
    @media screen and (max-width: $size-xs-max) {
      background-attachment: scroll;
    }
  }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
  content: "";
  display: block;
  height: $size-nav-height; /* fixed header height*/
  margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.nom {
  text-transform: uppercase;
  color: $color-2;
  white-space: nowrap;
}

.uper-case {
  text-transform: uppercase;
}

.blue-list {
  li {
    color: $color-2;
  }
}

/////////// HEADER - style movel
section.section-header.index {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  div.module-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $size-max-m-2) {
      width: 100%;
      transition: 0.5s;
    }

    div.caption {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 49vw;
      transition: 0.5s;
      @media screen and (max-width: $size-max-2) {
        height: 51vw;
        transition: 0.5s;
        @media screen and (max-width: $size-max-4) {
          height: 51vw;
          transition: 0.5s;
          /////Mobile style
          @media screen and (max-width: $size-max-m-1) {
            height: 54vw;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-2) {
              height: 115vw;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-4) {
                height: 150vw;
                transition: 0.5s;
              }
            }
          }
        }
      }
      ////// Part do custom with the site style
      h3 {
        text-shadow: -1px -1px 12px rgba(0, 0, 0, 0.39), -4px 3px 1px rgba(0, 0, 0, 0.7);
        font-family: $font-family-2;
        color: $color-white;
        text-transform: uppercase;
        text-align: center;
        font-weight: 400;
        transition: 0.5s;
      }
      h3 {
        font-size: 50px;
        //line-height: 145px;
        text-align: center;
        transition: 0.5s;
        @media screen and (max-width: $size-max-1) {
          font-size: 70px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-2) {
            font-size: 60px;
            line-height: 115px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-3) {
              font-size: 50px;
              line-height: 95px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-4) {
                font-size: 30px;
                line-height: 65px;
                transition: 0.5s;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                //////// Mobile style - text
                @media screen and (max-width: $size-max-m-4) {
                  font-size: 25px;
                  line-height: 30px;
                  transition: 0.5s;
                }
              }
            }
          }
        }
      }

      h3.sub-title-header {
        font-size: 45px;
        line-height: 55px;
        margin-top: 25px;
        text-align: center;
        @media screen and (max-width: $size-max-1) {
          font-size: 36px;
          margin-top: 5px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-2) {
            font-size: 28px;
            margin-top: 0;
            transition: 0.5s;
            @media screen and (max-width: $size-max-3) {
              font-size: 25px;
              line-height: 35px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-4) {
                font-size: 18px;
                line-height: 35px;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
  }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import "menu";
// @import 'menu-centre';

/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import "footer";

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/soumission';

/*******************************************************************************
* COMMON TO ALL PAGES
*/

/*****************************************************/
/* INDEX.PHP
/*****************************************************/

/*******************************************************************************
* X PAGE
*/

.pageX {
  section.header {
    transition: all 0.2s linear;
    &:hover {
      transition: all 0.2s linear;
    }
  }
}
.index {
  section.section-logos {
    background: $color-white;
    padding: 30px 10px;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;
    @extend .justify-evenly;
    text-align: center;
    & > div {
      margin: 20px auto;
    }
    img {
      margin: auto;
    }

    h3 {
      font-size: 22px;
      color: $color-logo;
      font-weight: 700;
    }
    div.icom:nth-child(2) {
      h3 {
        font-weight: 300;
      }
    }
    p {
      color: $color-logo;
      max-width: 800px;
    }
    .icon {
      @extend .flex;
    }
  }

  section.section-01 {
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-stretch;
    @extend .justify-center;
    & > div {
      width: 50%;
      background-size: cover;
      background-position: center;
      padding: 100px 0;
      @extend .flex;
      @extend .items-center;
      @extend .justify-center;
      @extend .flex-wrap;
      &.left {
        background-image: url("../images/section_02_bg.jpg");
        padding: 100px 30px;
        img {
          border-radius: 5px;
          border: 3px solid $color-white;
          box-shadow: 10px 10px 100px $color-black, -10px -10px 100px $color-black;
        }
      }
      &.right {
        background-color: $color-primary;
        transition: .5s;
        @media screen and (max-width: $size-max-m-3) {
          padding: 45px 5px;
          transition: .5s;
        }
        .text-box {
          width: 100%;
          padding: 0 10% 0 30px;
          border-top: 2px solid $color-white;
          border-bottom: 2px solid $color-white;
          @media screen and (max-width: $size-max-m-3) {
            padding: 24px 15px;
            transition: .5s;
          }
        }
      }
    }
    @media screen and (max-width: $size-xs-max) {
      & > div {
        width: 100%;
      }
    }
  }

  section.section-02 {
    @extend section.section-01;
    & > div.left {
      background-image: url("../images/section_04_bg.jpg");
    }
    & > div.right {
      @extend .items-start;
      @extend .justify-start;
      padding-left: 20px;
      padding-right: 20px;
      div {
        padding-right: 20px;
      }
      h3 {
        color: $color-2;
        font-size: 30px;
        text-transform: uppercase;
      }
    }
  }
}

.shadow {
  position: relative;
  &::after {
    content: "";
    z-index: 1;
    width: 100%;
    top: 100%;
    height: 40px;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  }
}

section.section-header.historic.index {
  & > div.right {
    padding-top: 20px;
  }
}

section.section-02.about {
  @extend section.section-01;
  & > div.left {
    background-image: url("../images/background_02.jpg");
    img {
      border: 0;
    }
  }
  & > div.right {
    @extend .items-start;
    @extend .justify-start;
    background: $color-black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    @media screen and (max-width: $size-max-m-3) {
      padding: 10px;
      transition: 0.5s;
    }
    div {
      padding-right: 20px;
    }
    h3 {
      color: $color-2;
      font-size: 30px;
      text-transform: uppercase;
      @media screen and (max-width: 1450px) {
        font-size: 30px;
        transition: 0.5s;
        @media screen and (max-width: 1250px) {
          font-size: 25px;
          transition: 0.5s;
          @media screen and (max-width: 1099px) {
            font-size: 20px;
            transition: 0.5s;
          }
        }
      }
    }
  }
}

section.section-01.service-page.last-services {
  div.left {
    img {
      border: 0;
      box-shadow: none;
    }
  }

  .right {
    padding-top: 20px;
    .lister-master {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 5vw;
      flex-wrap: wrap;

      div.content-lists {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;

        .list-nos-valeures {
          li.items {
            font-weight: 300;
          }
        }
      }
    }
  }
}

/////////////// Burst style herre
section.section-bursts {
  div.section-icon-bursts.container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px 20px;

    .row {
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      transition: 0.5s;
      .icon-bursts {
        .row {
          div.icon-burst {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 350px;
            width: 30%;
          }

          div.burst-generic {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 25px;
            transition: 0.5s;

            div.content-icons-imgs {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              img:nth-child(1) {
              }
              img:nth-child(2) {
                position: absolute;
                opacity: 0;
                transition: 0.5s;
              }
            }
            p {
              display: none;
            }
            h3 {
              text-align: center;
              color: $color-1;
              font-weight: 600;
              font-size: 21px;
              transition: 0.5s;
              @media screen and (max-width: 767px) {
                font-size: 18px;
                transition: 0.5s;
              }

              span.strong-upercase {
                text-transform: uppercase;
              }
            }
          }
        }
        a:hover {
          div.burst-generic {
            div.content-icons-imgs {
              img:nth-child(1) {
                opacity: 0;
                transition: 0.5s;
              }
              img:nth-child(2) {
                position: absolute;
                opacity: 1;
                transition: 0.5s;
              }
            }
            .text-bloc {
              h3 {
                color: #00abff;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }
  }
}

.title-section-top {
  text-transform: uppercase;
  color: $color-2;
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 600;
  //margin-top: -45px;
  margin-bottom: 25px;
  padding-left: 25px;
  width: 100%;
  text-align: left;
  transition: 0.5s;

  @media screen and (max-width: 1450px) {
    font-size: 30px;
    transition: 0.5s;
    @media screen and (max-width: 1250px) {
      font-size: 25px;
      transition: 0.5s;
      @media screen and (max-width: 1099px) {
        font-size: 20px;
        transition: 0.5s;
      }
    }
  }
}

section.section-01.emplois-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-basic;

  div.container-fluid.row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 75px 22vw;
    @media screen and (max-width: $size-max-m-1) {
      padding: 75px 15px;
      transition: 0.5s;
    }

    div.content-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
    }
  }

  p,
  h3 {
    text-align: center;
  }
  p {
    font-size: 31px;
    line-height: 40px;
    @media screen and (max-width: $size-max-2) {
      font-size: 28px;
      line-height: 35px;
      transition: 0.5s;
      @media screen and (max-width: $size-max-3) {
        font-size: 20px;
        line-height: 25px;
        transition: 0.5s;
        @media screen and (max-width: $size-max-4) {
          font-size: 18px;
          line-height: 22px;
          transition: 0.5s;
        }
      }
    }
  }

  h3 {
    font-weight: 500;
    font-size: 40px;
    width: 100%;

    @media screen and (max-width: $size-max-2) {
      font-size: 35px;
      transition: 0.5s;
      @media screen and (max-width: $size-max-3) {
        font-size: 25px;
        transition: 0.5s;
        @media screen and (max-width: $size-max-4) {
          font-size: 20px;
          transition: 0.5s;

          @media screen and (max-width: $size-max-m-2) {
            font-size: 18px;

            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            transition: 0.5s;
          }
        }
      }
    }
  }
}

//////////// Emplois movel style
section.list-emplois {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: $color-basic;

  div.container-inner-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7vw;
    transition: 0.5s;
    @media screen and (max-width: $size-max-1) {
      padding: 0 2vw;
      transition: 0.5s;
      @media screen and (max-width: $size-max-3) {
        padding: 0 0;
        transition: 0.5s;
      }
    }
  }

  div.container-flui.row {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (max-width: $size-max-m-1) {
      justify-content: flex-start;
      transition: 0.5s;
      @media screen and (max-width: $size-max-m-3) {
        flex-direction: column;
        transition: 0.5s;
      }
    }

    div.unique-job {
      // width: 48%;
      margin: 15px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background: transparent;
      flex-direction: column;
      margin-bottom: 85px;
      @media screen and (max-width: $size-max-2) {
        // width: 44%;
        transition: 0.5s;
        @media screen and (max-width: $size-max-m-1) {
          // width: 100%;
          transition: 0.5s;
          @media screen and (max-width: $size-max-m-3) {
            margin: 0;
            padding: 0;
            justify-content: center;
            align-items: center;
            transition: 0.5s;
          }
        }
      }
    }

    div.body-offert {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      @media screen and (max-width: $size-max-3) {
        align-items: center;
        transition: 0.5s;
        @media screen and (max-width: $size-max-m-3) {
          flex-direction: column;
          transition: 0.5s;
        }
      }
    }

    div.left-side-photo {
      // width: 40%;
      // min-width: 250px;
      background: transparent;
      margin-right: 15px;
      @media screen and (max-width: $size-max-2) {
        // width: 40%;
        transition: 0.5s;
        @media screen and (max-width: $size-max-3) {
          // width: 30%;
          // min-width: 150px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-m-1) {
            // width: 40%;
            // min-width: 250px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-3) {
              width: 100%;
              padding: 10px;
              margin: 0;
              transition: 0.5s;
            }
          }
        }
      }

      img {
        width: 100%;
      }
    }

    div.content-informations {
      min-width: 50vw;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .link-pdf {
        color: $color-2;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
        margin-top: 25px;
        transition: 0.5s;
        @media screen and (max-width: $size-max-2) {
          margin-bottom: 0;
          // margin-top: 0;
          transition: 0.5s;
        }
        &:hover {
          color: $color-black;
          transition: 0.5s;
        }
      }

      @media screen and (max-width: $size-max-2) {
        // width: 60%;
        transition: 0.5s;
        @media screen and (max-width: $size-max-3) {
          // width: 75%;
          transition: 0.5s;
          @media screen and (max-width: $size-max-m-3) {
            // width: 100%;
            padding: 10px;
            transition: 0.5s;
          }
        }
      }

      h3 {
        text-transform: none;
      }
      h3.tirle-emplopsi {
        color: $color-2;
        font-weight: 600;
        font-size: 22px;
        @media screen and (max-width: $size-max-3) {
          font-size: 18px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-m-1) {
            font-size: 22px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-3) {
              font-size: 18px;
              transition: 0.5s;
            }
          }
        }
      }

      h3.content-date-top {
        color: $color-white;
        font-size: 15px;
        font-weight: 500;
        padding-top: 0;
        margin-bottom: 15px;
        @media screen and (max-width: $size-max-3) {
          font-size: 13px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-m-1) {
            font-size: 15px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-3) {
              font-size: 13px;
              transition: 0.5s;
            }
          }
        }
      }

      h3.titl-organisations {
        color: $color-white;
        font-weight: 400;
        font-size: 22px;
        @media screen and (max-width: $size-max-3) {
          font-size: 18px;
          padding-top: 5px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-m-1) {
            font-size: 22px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-3) {
              font-size: 18px;
              transition: 0.5s;
            }
          }
        }
      }

      div.content-offert {
        width: 100%;
        p {
          padding-top: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          // margin-top: 15px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-3) {
            font-size: 14px;
            margin-top: 10px;
            padding-bottom: 7px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-1) {
              font-size: 16px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-3) {
                font-size: 14px;
                transition: 0.5s;
              }
            }
          }
        }
        a {
          color: $color-2;
          cursor: pointer;
          transition: 0.7s;
          &:hover {
            color: $color-black;
            transition: 0.5s;
          }
        }

        .read-less, .read-more {
          margin: 0px 0;
          margin-bottom: 25px;
          width: 100%;
          // display: flex;
          // justify-content: flex-start;
          // align-items: flex-start;
        }
      }
    }

    div.footer-footer {
      display: flex;
      justify-content: center;
      width: 100%;
      border-top: 2px solid #ccc;
      margin-top: 25px;
      padding-top: 15px;

      @media screen and (max-width: $size-max-m-3) {
        width: 95%;
        margin-bottom: 52px;
        transition: 0.5s;
      }

      div.btn-border {
        width: 250px;
        height: 65px;
        display: flex;
        align-content: center;
        justify-content: center;
        background: $color-basic-2;
        border: 2px solid transparent;
        padding: 5px;
        transition: 0.5s;

        @media screen and (max-width: $size-max-2) {
          width: 200px;
          height: 55px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-3) {
            width: 150px;
            height: 45px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-1) {
              width: 250px;
              height: 65px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-3) {
                width: 150px;
                height: 45px;
                transition: 0.5s;
              }
            }
          }
        }

        &:hover {
          border: 2px solid $color-white;
          background: $color-1;
          transition: 0.5s;
        }

        a {
          color: $color-white;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 22px;
          transition: 0.7s;

          @media screen and (max-width: $size-max-2) {
            font-size: 20px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-3) {
              font-size: 16px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-1) {
                font-size: 22px;
                transition: 0.5s;
                @media screen and (max-width: $size-max-m-3) {
                  font-size: 16px;
                  transition: 0.5s;
                }
              }
            }
          }
        }
      }
    }
  }
}

div.conadidature-spontane {
  width: 84%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  margin-bottom: 75px;
  padding: 35px 15px;
  transition: 0.5s;

  @media screen and (max-width: $size-max-3) {
    width: 95%;
    transition: 0.5s;
  }

  h3.text-c-white {
    font-size: 22px;
    text-align: center;
  }

  h3.nom {
    font-size: 35px;
    transition: 0.5s;
    @media screen and (max-width: $size-max-3) {
      font-size: 28px;
      transition: 0.5s;
      @media screen and (max-width: $size-max-m-1) {
        font-size: 25px;
        transition: 0.5s;
        @media screen and (max-width: $size-max-m-2) {
          font-size: 19px;
          transition: 0.5s;
        }
      }
    }
  }

  div.bouton-spantanee {
    width: 285px;
    height: 65px;
    background: $color-basic-2;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    transition: 0.5s;
    &:hover {
      border: 2px solid $color-white;
      background: $color-1;
      transition: 0.5s;
    }
    a {
      width: 100%;
      height: 100%;
      color: $color-white;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

//////////// Page resalisations
section.section-01.realisation-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-basic;
  div.container-fluid.row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ///// Top -coentent
    div.container-fluid.top-title {
      display: none;
    }

    div.section-projets {
      display: flex;
      align-items: center;
      justify-content: center;

      //// ::Before images content
      div.container-fluid.section-projets--all {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        //// Unique - product
        a.unique-project {
          width: 22%;
          min-width: 350px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 15px;
          margin-bottom: 35px;
          @media screen and (max-width: $size-max-m-1) {
            min-width: 300px;
            @media screen and (max-width: $size-max-m-3) {
              min-width: 300px;
            }
          }

          .content-img {
            height: 200px;
            border-radius: 5px;
            overflow: hidden;
            -webkit-box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.49);
            -moz-box-shadow:    1px 1px 9px 0px rgba(0, 0, 0, 0.49);
            box-shadow:         1px 1px 9px 0px rgba(0, 0, 0, 0.49);
          }
          div.content-descriptions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //overflow: hidden;
            // padding-top: 20px;
            // margin-top: 25px;
            //height: 150px;
            width: 100%;

            h3.title-project {
              color: $color-white;
              font-weight: 600;
              font-size: 20px;
              text-align: left;
              width: 100%;
            }

            .descriptions-paragraph {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-top: 15px;

              p {
                padding: 0;
                font-size: 20px;
                line-height: 30px;
                font-weight: 400;

                @media screen and (max-width: $size-max-2) {
                  font-size: 17px;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}

///////////// Top page soumission
section.section-top-infos {
  background: $color-basic;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;

  div.container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      transition: 0.5s;
      @media screen and (max-width: $size-max-m-2) {
        font-size: 17px;
        transition: 0.5s;
      }
    }
  }
}

////////// PAGE CONTACT - SECTION 01
section.section-01-corrdonnees {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-basic;
  padding: 0;
  margin: 0;
  @media screen and (max-width: $size-max-m-2) {
    flex-direction: column;
    padding-top: 65px;
  }

  div#content {
    h3#firstHeading {
      font-weight: 700;
    }
  }

  #bodyContent {
    p {
      padding: 10px 0;
    }
  }

  div.right-side-mnap,
  div.left-side.informations {
    width: 50%;
    transition: 0.5s;
    @media screen and (max-width: $size-max-m-2) {
      width: 100%;
      transition: 0.5s;
    }
  }

  div.left-side.informations {
    padding-top: 0 !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 0 10vw;
    padding-right: 8vw;
    transition: 0.5s;
    @media screen and (max-width: $size-max-m-2) {
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      padding-bottom: 65px;
      transition: 0.5s;
    }

    h3.title-top {
      width: 100%;
      color: $color-2;
      font-size: 40px;
      text-align: right;
      margin-bottom: 5px;
      font-weight: 500;
      transition: 0.5s;
      @media screen and (max-width: $size-max-2) {
        font-size: 32px;
        transition: 0.5s;
        @media screen and (max-width: $size-max-3) {
          font-size: 25px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-m-1) {
            font-size: 20px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-2) {
              text-align: center;
              transition: 0.5s;
            }
          }
        }
      }
    }

    div.address-content {
      border-top: 2px solid $color-2;
      border-bottom: 2px solid $color-2;
      margin-top: 25px;
      padding: 25px 0;
      width: 100%;
      @media screen and (max-width: $size-max-2) {
        font-size: 25px;
        padding: 5px 0;
        transition: 0.5s;
        @media screen and (max-width: $size-max-m-2) {
          margin: 10px;
          padding: 10px;
          transition: 0.5s;
        }
      }

      h3.adresse-content {
        text-align: right;
        color: $color-white;
        font-size: 30px;
        line-height: 35px;
        font-weight: 500;
        @media screen and (max-width: $size-max-2) {
          font-size: 25px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-3) {
            font-size: 20px;
            line-height: 28px;
            padding: 0;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-1) {
              font-size: 18px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-2) {
                font-size: 22px;
                line-height: 35px;
                text-align: center;
                transition: 0.5s;
              }
            }
          }
        }
      }
    }

    div.contact-content {
      border-bottom: 2px solid $color-2;
      margin-top: 25px;
      padding: 25px 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      @media screen and (max-width: $size-max-m-2) {
        justify-content: center;
        align-items: center;
        transition: 0.5s;
      }

      a {
        color: $color-2;
        transition: 0.5s;
        &:hover {
          color: $color-basic-2;
          transition: 0.5s;
        }
      }

      h3.phone,
      h3.fax {
        line-height: 20px;
        text-align: right;
        @media screen and (max-width: $size-max-m-2) {
          flex-direction: column;
          display: flex;
          width: 100%;
          text-align: center;
          transition: 0.5s;
        }
        span {
          color: $color-white;
          font-size: 22px;
          font-weight: 400;
          @media screen and (max-width: $size-max-2) {
            font-size: 20px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-3) {
              font-size: 18px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-1) {
                font-size: 15px;
                transition: 0.5s;
                @media screen and (max-width: $size-max-m-2) {
                  font-size: 17px;
                  margin-bottom: 7px;
                  transition: 0.5s;
                }
              }
            }
          }
        }
        a {
          font-size: 35px;
          font-weight: 400;
          @media screen and (max-width: $size-max-2) {
            font-size: 30px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-3) {
              font-size: 28px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-1) {
                font-size: 25px;
                transition: 0.5s;
              }
            }
          }
        }
      }

      a.email-cliente {
        font-size: 32px;
        font-weight: 500;
        text-align: right;
        margin-top: 25px;
        @media screen and (max-width: $size-max-2) {
          font-size: 28px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-3) {
            font-size: 24px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-1) {
              font-size: 18px;
              transition: 0.5s;
            }
          }
        }
      }
    }

    div.heure-ouverture {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      @media screen and (max-width: $size-max-m-2) {
        justify-content: center;
        align-items: center;
        transition: 0.5s;
        width: 100%;
      }

      h3.title-ouverture {
        color: $color-white;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 7px;
        text-transform: uppercase;
        margin-top: 15px;
        @media screen and (max-width: $size-max-2) {
          font-size: 27px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-3) {
            font-size: 22px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-1) {
              font-size: 18px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-2) {
                font-size: 20px;
                transition: 0.5s;
              }
            }
          }
        }
      }

      h3 {
        color: $color-white;
        font-weight: 500;
        font-size: 28px;
        padding-top: 5px;
        @media screen and (max-width: $size-max-2) {
          font-size: 25px;
          transition: 0.5s;
          @media screen and (max-width: $size-max-3) {
            font-size: 20px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-m-1) {
              font-size: 16px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-2) {
                font-size: 20px;
                transition: 0.5s;
              }
            }
          }
        }
        span {
          color: $color-2;
          font-size: 30px;
          @media screen and (max-width: $size-max-2) {
            font-size: 27px;
            transition: 0.5s;
            @media screen and (max-width: $size-max-3) {
              font-size: 22px;
              transition: 0.5s;
              @media screen and (max-width: $size-max-m-1) {
                font-size: 18px;
                transition: 0.5s;
                @media screen and (max-width: $size-max-m-2) {
                  font-size: 22px;
                  transition: 0.5s;
                }
              }
            }
          }
        }
      }
    }
  }

  div.right-side-mnap {
    //width: 50%;
  }
}

div.section-form {
  background: $color-basic;
  padding: 45px 15px;
  padding-bottom: 95px;

  .form-control {
    height: 55px;
    border: 2px solid $color-2;
    border-radius: 1px;
    font-size: 18px;
  }

  #message.form-control {
    min-height: 150px;
    border: 2px solid $color-2;
    border-radius: 1px;
  }

  #fileDoc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 13px;
  }

  div.help-block li {
    font-size: 0.9rem;
    color: #a94442;
    position: absolute;
    margin-top: -12px;
  }

  .title-input {
    color: $color-2;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  ////// Chesckbox- style
  .checkBoX_content {
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 15px;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      color: $color-white;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 20px;
      transition: 0.5s;

      span {
        //margin-top: -4px;
        transition: 0.5s;
      }
    }

    /* Hide the browser's default checkbox */
    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      transition: 0.5s;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 2px solid $color-2;
      background-color: transparent;
      border-radius: 3px;
      transition: 0.5s;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
      border: 2px solid $color-2;
      border-radius: 3px;
      transition: 0.7s;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: $color-white;
      border: 1px solid $color-2;
      transition: 0.5s;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      //display: none;
      opacity: 0;
      transition: 0.5s;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      //display: block;
      opacity: 1;
      transition: 0.5s;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 0 solid $color-white;
      background: $color-2;
      //border-width: 8px 6px 5px 7px;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      transition: 0.5s;
    }
  }

  div.footer-form {
    display: flex;
    justify-content: center;
    align-items: center;
    div.buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 35px;

      div.btn-submit {
        width: 300px;
        height: 65px;
        margin: 0;
        padding: 0;

        button#submit {
          border: 0 solid $color-white;
          background: $color-2;
          padding: 5px;
          width: 100%;
          height: 65px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.5s;

          span {
            border: 1px solid $color-white;
            width: 100%;
            height: 52px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            font-weight: 600;
            transition: 0.5s;
          }
        }
        &:hover {
          transition: 0.5s;
          button#submit {
            transition: 0.5s;
            span {
              border: 1px solid $color-black;
              color: $color-black;
              transition: 0.5s;
            }
          }
        }
      }
    }
  }
}

.page-description {
  p {
    font-size: 18px;
    font-weight: 600;
  }
}

.mp-0 {
  padding: 0;
  margin-top: -35px;
}

.text-c-white {
  color: $color-white;
}

.full-text {
  display: none;
}

.text-box {
  @media screen and (max-width: $size-max-m-3) {
    padding: 24px 15px;
    //margin-bottom: 35px;
    transition: .5s;
  }
  p {
    transition: 0.5s;
    padding: 1vw 0;
    @media screen and (max-width: 1375px) {
      font-size: 18px;
      font-weight: 400;
      transition: 0.5s;
      @media screen and (max-width: $size-max-m-2) {
        text-align: center;
        margin-bottom: 25px;
        transition: 0.5s;
      }
    }
  }
}

.title-section-top {
  @media screen and (max-width: $size-max-m-2) {
    text-align: center;
    margin: 0;
    padding: 0;
    padding: 25px 0;
    transition: 0.5s;
  }
}

.list-elts {
  @media screen and (max-width: $size-max-m-2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      text-align: center;
      line-height: 20px;
      margin-bottom: 25px;
    }
  }
}

.title-empty {
  color: #ccc;
  opacity: .8;
}

.pt-2 {
  padding-top: 15px!important;
}

.title-md {
  @media screen and (max-width: 1450px) {
    font-size: 30px;
    transition: 0.5s;
    @media screen and (max-width: 1250px) {
      font-size: 25px;
      transition: 0.5s;
      @media screen and (max-width: 1099px) {
        font-size: 20px;
        transition: 0.5s;
      }
    }
  }
}

.less-marin-t {
  margin-top: -5vw;
  @media screen and (max-width: 1099px) {
    margin-top: 0;
    transition: 0.5s;
  }
}

.border-none {
  border: 0 !important;
}

.bt-none {
  border-top: 0 !important;
}

.bb-none {
  border-bottom: 0 !important;
}

.border-radius {
  border: 5px solid $color-white !important;
  border-radius: 5px;
}

.shadow-none {
  box-shadow: none !important;
}

div.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display:none;
  background-color: rgba(0,0,0,0.7);
  background-size: cover;
  z-index: 9999;
  &.active {
      display: flex;
      justify-content: center;
      align-items: center;
  }
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid $bg-color-body;
  border-radius: 50%;
  border-top: 16px solid $color-secondary;
  border-bottom: 16px solid $color-primary;
  width: 120px;
  height: 120px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.8), inset 0px 0px 5px rgba(0,0,0,0.8);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
  /*******************************************************************************
    * COMMON TO ALL PAGES
    */

  /*******************************************************************************
    * X PAGE OVERRIDES
    */
}

@media screen and (max-width: $size-sm-max) {
  /*******************************************************************************
    * COMMON TO ALL PAGES
    */

  /*******************************************************************************
    * X PAGE OVERRIDES
    */
}

@media screen and (max-width: $size-xs-max) {
  /*******************************************************************************
    * COMMON TO ALL PAGES
    */
  section.header {
    h2 {
      font-size: 3em;
    }
  }

  /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
