// *************************************************************
// Ce fichier load les fonts et
// définit les classes de font du theme
//

// *************************************************************
// >> Choisir les fonts à loader ici (décommenter celles utilisées ou ajouter des nouvelles fonts)

//***** LOCAL FONTS dans folder /fonts/ *****//
// généré par https://google-webfonts-helper.herokuapp.com/fonts


/* fira-sans-condensed-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/barlow/Barlow-Regular.eot'); /* IE9 Compat Modes */
  src: local('Barlow Regular'), local('Barlow-Regular'),
       url('../fonts/barlow/Barlow-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      // url('../fonts/barlow/Barlow-Regular.woff2') format('woff2'), /* Super Modern Browsers */
      // url('../fonts/barlow/Barlow-Regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow/Barlow-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow/Barlow-Regular.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* fira-sans-condensed-500 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/barlow/Barlow-Medium.eot'); /* IE9 Compat Modes */
  src: local('Barlow Medium'), local('FiraSansCondensed-Medium'),
       url('../fonts/barlow/Barlow-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      // url('../fonts/barlow/Barlow-Medium.woff2') format('woff2'), /* Super Modern Browsers */
      // url('../fonts/barlow/Barlow-Medium.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow/Barlow-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow/Barlow-Medium.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* fira-sans-condensed-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/barlow/Barlow-Bold.eot'); /* IE9 Compat Modes */
  src: local('Barlow Bold'), local('Barlow-Bold'),
       url('../fonts/barlow/Barlow-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       //url('../fonts/barlow/Barlow-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       //url('../fonts/barlow/Barlow-Bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/barlow/Barlow-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/barlow/Barlow-Bold.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* libre-baskerville-regular - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-regular.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}
/* libre-baskerville-700 - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Libre Baskerville Bold'), local('LibreBaskerville-Bold'),
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/libre-baskerville/libre-baskerville-v5-latin-700.svg#LibreBaskerville') format('svg'); /* Legacy iOS */
}

//***** GOOGLE HOSTED *****//
//***** SANS SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,700i,900');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i');
// @import url('https://fonts.googleapis.com/css?family=Oswald');
// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans');
// @import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700');

//***** SERIF *****//
// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
// @import url('https://fonts.googleapis.com/css?family=Alegreya');


// >> C'est ici qu'on met les font-family à utiliser
$font-family-1: "Barlow", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$font-family-2: $font-family-1;
//$font-family-2: "Libre Baskerville", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
// $font-family-3: "Roboto Condensed", arial, Helvetica;

// *************************************************************
// >> Les CLASSES de fonts qui sont utilisées dans le theme.
//    Avec les fonts system par défaut

// fira-sans
.font-1 { font-family: $font-family-1; font-weight: 400; }
.font-1-light { @extend .font-1; font-weight: 300; }
.font-1-medium { @extend .font-1; font-weight: 500; }
.font-1-bold { @extend .font-1; font-weight: 700; }
// Libre Baskerville
.font-2 { font-family: $font-family-2; font-weight: 400; }
.font-2-bold { @extend .font-2; font-weight: 700; }

// MAIN FONTS
.font-main {
    @extend .font-1-bold;
    @extend .text-color;
    font-size: 22px;
    @media screen and (max-width: $size-xs-max) {
        font-size: 16px;
    }
}
h3 {
    @extend .font-1-bold;
    @extend .text-color-header;
    font-size: $font-size-24;
    line-height: 1.3;
    padding-top: 15px;
    @media screen and (max-width: $size-xs-max) {
        font-size: $font-size-18;
    }
}

// NAV FONTS
// font pour les items de premier niveau
.font-nav-items {
    @extend .font-1-medium;
    font-size: $font-size-16;
    line-height: 1;
    text-transform: uppercase;
}
// font pour les entete dans les dropdowns
.font-nav-drop-header {
    @extend .font-2-bold;
    font-size: $font-size-24;
}
// font pour les items dans les dropdowns
.font-nav-drop-items {
    @extend .font-1-bold;
    font-size: $font-size-16;
    line-height: 1;
}
.font-nav-phone {
    @extend .font-2-bold;
    font-size: $font-size-18;
}

// si le logo est du texte
.font-nav-logo {
    @extend .font-2;
    font-size: $font-size-28;
    @media screen and (max-width: $size-sm-max){
        font-size: $font-size-20;
    }
    @media screen and (max-width: $size-xs-max){
        font-size: $font-size-14;
    }
}

// FOOTER FONTS

.font-footer {
    @extend .font-1;
    font-size: $font-size-14;
}
