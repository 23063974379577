.slideshow-wrap {
    position: relative;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .caption {
      top: 50%;
      z-index: 3;

      position: absolute;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
      .logo-slide {
        max-width: 30%;
        margin: auto;
        border: 3px solid rgba($color-logo, 0.7);
        padding: 20px 30px;
        display: inline-block;
        background: rgba(255, 255, 255, 0.5);
        @media screen and (max-width: 865px) {
            max-width: 50%;
            @media screen and (max-width: 480px) {
                max-width: 65%;
            }
        }
      }
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-size: 1.5vw;
        text-align: center;
        padding-bottom:0.5em;
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: 11px;
            border-width: 1px;
            padding: 5px;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        .title {
            font-size: 26px;
        }
        .subtitle {
            font-size: 16px;
        }

    }
}
